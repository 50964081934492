//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ChangClause",
  data() {
    return {
      // loading: true
    };
  },
  methods: {},
  props: {
    loading: false,
    ruleText: {
      type: Array,
      default: []
    },
    luggage: {
      type: String,
    },
    ruleType: {
      type: Number,
      default: 0,
    },
    ruleComment: {
      type: Array,
      default: () => []
    },
    airLineCode: {
      type: String,
      default: ''
    },
    prompts: {
      type: Array,
      default: () => []
    }
  },
  created() {}
};
