const __request = require(`../__request/flight-admin/__request_contentType_json`);

// 国内改签订单日志
export default (data) => {
let pParameter = {
    method: 'post',
    urlSuffix: '/buyer/dom/manage/order/change/log/list',
    data:data
  }

  return __request(pParameter)
}

