const __request = require(`../__request/flight-admin/__request_contentType_json`);

// 国内订单操作日志
export default (data) => {
  let pParameter = {
    method: 'post',
    // urlSuffix: '/buyer/dom/manage/log/list',
    urlSuffix: '/buyer/dom/manage/orderLog',
    data: data
  }

  return __request(pParameter)
}

