const __request = require(`../__request/flight-admin/__request_contentType_json`);

// 国内退票订单详情
export default (data) => {
let pParameter = {
    method: 'post',
    urlSuffix: '/buyer/dom/manage/refundDetail',
    data:data
  }

  return __request(pParameter)
}

